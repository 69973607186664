import React from 'react';

import { BaseTemplate } from '@templates';
import { SEO, PageSection, Button } from '@components';

import './404.scss';
import bg from '@images/background-404.png';

const NotFoundPage = () => (
  <BaseTemplate>
    <SEO title="Sorry, but that page does not seem to exist" />
    <PageSection className="section-404">
      <h1 className="text-4xl text-ac800">404 Error</h1>
      <p className="text-lg">
        <i>or in human language,</i>
        <br />
        <span className="text-medium text-sh900">
          “We’re sorry, but that page does not seem to exist.”
        </span>
        <Button
          className="outline accent display-block"
          destination="/"
          buttonLabel="Head to our Homepage"
        />
      </p>
      <img src={bg} alt="404" className="animated three-seconds infinite pulse  bg" />
    </PageSection>
  </BaseTemplate>
);

export default NotFoundPage;
